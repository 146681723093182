import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { BreadcrumbsWrapper } from '../../../common';
import { useVelocityControl } from '../../../services';
import { EditCardSpendingControlForm } from './EditCardSpendingControlForm';

export function EditCardSpendingControlPage() {
    const { id = '' } = useParams();

    const {
        data: [controlDetails],
    } = useVelocityControl({ params: { id } });

    return (
        <PageContainer testId="editSCPage">
            <BreadcrumbsWrapper />

            {controlDetails && (
                <EditCardSpendingControlForm controlDetails={controlDetails} id={id} />
            )}
        </PageContainer>
    );
}
