import { Box } from '@mui/material';
import React from 'react';

import { useMatchedPath } from '../../hooks';
import { Breadcrumbs } from './Breadcrumbs';

type BreadcrumbsWrapperProps = {
    children?: React.ReactNode;
};

export function BreadcrumbsWrapper({ children }: BreadcrumbsWrapperProps) {
    const matchedPath = useMatchedPath();

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: '24px',
                minHeight: '52px',
            }}
        >
            <Breadcrumbs
                explicitBreadcrumbs={matchedPath?.explicitBreadcrumbs || []}
                explicitLinksForBreadcrumbs={matchedPath?.explicitLinksForBreadcrumbs || []}
            />
            {children}
        </Box>
    );
}
