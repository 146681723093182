import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import type { z } from 'zod';

import { TypographyWithTooltip, ControlledInput, FormActionButtons } from '@dock/react';
import { gridInput } from '@dock/react-mui';
import { externalReferenceStepSchema } from '@dock/validation';

import commonLang from '../../../lang/common';

enum ExternalReferenceKeys {
    EXTERNAL_REFERENCE = 'externalReference',
}

type ExternalReferenceFormValues = z.infer<typeof externalReferenceStepSchema>;

type ExternalReferenceStepProps = {
    handleBackStep: () => void;
    handleFormSubmit: (values: string) => void;
    handleCloseModal: () => void;
    defaultValues: ExternalReferenceFormValues;
    isLoading: boolean;
    submitButtonText: string;
};

const TOOLTIP_HEIGHT = '76px';

export function ExternalReferenceStep({
    defaultValues,
    handleBackStep,
    handleCloseModal,
    handleFormSubmit,
    isLoading,
    submitButtonText,
}: ExternalReferenceStepProps) {
    const { control, handleSubmit } = useForm({
        defaultValues,
        resolver: zodResolver(externalReferenceStepSchema),
    });

    const onSubmitHandler = (values: ExternalReferenceFormValues) => {
        handleFormSubmit(values[ExternalReferenceKeys.EXTERNAL_REFERENCE] || '');
    };

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container>
                <Grid item xs={11} sx={gridInput}>
                    <ControlledInput
                        control={control}
                        label={commonLang.FORMS.EXTERNAL_REFERENCE}
                        name={ExternalReferenceKeys.EXTERNAL_REFERENCE}
                        isFullWidth
                        helperText={commonLang.FORMS.EXTERNAL_REFERENCE_HELPER_TEXT}
                        testId="externalReferenceInput"
                    />
                </Grid>

                <Grid item xs={1} sx={{ ...gridInput, height: TOOLTIP_HEIGHT }}>
                    <TypographyWithTooltip
                        text={commonLang.TOOLTIP.EXTERNAL_REFERENCE}
                        variant="body2"
                        isWithIcon
                    />
                </Grid>
            </Grid>

            <FormActionButtons
                onCancelHandler={handleCloseModal}
                onBackHandler={handleBackStep}
                isLoading={isLoading}
                submitButtonText={submitButtonText}
            />
        </form>
    );
}
