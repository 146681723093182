import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs as MUIBreadcrumbs, Typography, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

type BreadcrumbsTypes = {
    explicitBreadcrumbs?: string[];
    explicitLinksForBreadcrumbs?: string[];
};

const TYPOGRAPHY_STYLES = { color: 'primary.main' };

const getRouteTo = (pathnames: string[], index: number) =>
    `/${pathnames.slice(0, index + 1).join('/')}`;

export function Breadcrumbs({
    explicitBreadcrumbs,
    explicitLinksForBreadcrumbs,
}: BreadcrumbsTypes) {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const pathnames = explicitLinksForBreadcrumbs?.length
        ? explicitLinksForBreadcrumbs
        : pathname.split('/').filter((path) => path);

    return (
        <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
            {pathnames.length > 1 &&
                pathnames.map((name, index) => {
                    const routeTo = getRouteTo(pathnames, index);
                    const isLast = index === pathnames.length - 1;
                    const decodedName = decodeURI(name);

                    if (pathnames.length === 1) {
                        return (
                            <Typography {...TYPOGRAPHY_STYLES}>
                                {explicitBreadcrumbs?.[0] || decodedName}
                            </Typography>
                        );
                    }

                    return isLast ? (
                        <Typography {...TYPOGRAPHY_STYLES} key={name}>
                            {explicitBreadcrumbs?.[explicitBreadcrumbs.length - 1] || decodedName}
                        </Typography>
                    ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                            underline="hover"
                            sx={{
                                '&:hover': {
                                    color: 'primary.main',
                                },
                                color: ({ palette }) => palette.grey[400],
                                cursor: 'pointer',
                            }}
                            key={name}
                            onClick={() => navigate(routeTo)}
                        >
                            {explicitBreadcrumbs?.[index] || decodedName}
                        </Link>
                    );
                })}
            {pathnames.length <= 1 && (
                <Typography {...TYPOGRAPHY_STYLES}>
                    {explicitBreadcrumbs?.[0] || decodeURI(pathname).slice(1)}
                </Typography>
            )}
        </MUIBreadcrumbs>
    );
}
