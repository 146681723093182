import { OptionType } from '@dock/react';
import { A2ATransfersInstrumentStatus } from '@dock/types-dock-partner';

import { a2aStatusMap } from './a2aStatusMap';

export const a2aStatusOptions: OptionType<A2ATransfersInstrumentStatus>[] = [
    // TODO:  value: InstrumentStatus.TERMINATED should be replaced with A2ATransferStatus.TERMINATED_OBSOLETE after BE fix
    // {
    //     label: a2aStatusMap[A2ATransfersInstrumentStatus.TERMINATED_OBSOLETE],
    //     value: A2ATransfersInstrumentStatus.TERMINATED_OBSOLETE,
    // },
    {
        label: a2aStatusMap[A2ATransfersInstrumentStatus.TERMINATED],
        value: A2ATransfersInstrumentStatus.TERMINATED,
    },
    {
        isDisabled: true,
        label: a2aStatusMap[A2ATransfersInstrumentStatus.ACTIVATED],
        value: A2ATransfersInstrumentStatus.ACTIVATED,
    },
    {
        isDisabled: true,
        label: a2aStatusMap[A2ATransfersInstrumentStatus.UNACTIVATED],
        value: A2ATransfersInstrumentStatus.UNACTIVATED,
    },
    {
        isDisabled: true,
        label: a2aStatusMap[A2ATransfersInstrumentStatus.LOCKED],
        value: A2ATransfersInstrumentStatus.LOCKED,
    },
];
