import { init, BrowserTracing, Replay } from '@sentry/react';

import { ENV_VARS } from '../common';

// https://docs.sentry.io/platforms/javascript/guides/react/
export const initSentry = () => {
    if (ENV_VARS.IS_PRODUCTION_BUILD) {
        init({
            dsn: ENV_VARS.SENTRY_URL,
            environment: ENV_VARS.ENVIRONMENT || 'dev',
            integrations: [new BrowserTracing(), new Replay()],
            // Set tracesSampleRate to 1.0 to capture 100%
            replaysOnErrorSampleRate: 1.0,
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            // of transactions for performance monitoring.
            tracesSampleRate: 1.0,
        });
    }
};
