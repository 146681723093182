import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { prefillDatesFromAlways } from '@dock/common';
import { FilterButton, SimpleTable, SearchBox, DateRangeDropDown } from '@dock/react';
import { useModal } from '@dock/react-hooks';

import { filterWrapperStyles, TableFiltersWrapper } from '../../../../common';
import accountDetailsLang from '../../../../lang/accountDetails';
import { useAccountDetailsTxs } from '../../../../services';
import { AccountTransactionFilters } from './AccountTransactionFilters';
import { DownloadContainer } from './DownloadContainer';
import { accountTxsRowMapper } from './formatters';
import { TransactionRow } from './TransactionRow';
import { transactionTableColumns } from './transactionTableColumns';
import { transformAccountTransactionsParams } from './transformAccountTransactionsParams';
import { useAccountTransactionsFilters } from './useAccountTransactionsFilters';

export function TransactionTable() {
    const { id: accountId = '' } = useParams();
    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useAccountTransactionsFilters();

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useAccountDetailsTxs({
        params: transformAccountTransactionsParams(filters, accountId),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    const preparedData = accountTxsRowMapper(data);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={accountDetailsLang.SEARCH}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={isPopFilterActive} onClick={setOpen} />
                    <DownloadContainer
                        filters={filters}
                        onApplyFilterClick={setOpen}
                        accountId={accountId}
                    />
                </Box>
            </TableFiltersWrapper>

            <AccountTransactionFilters
                onApply={handlePopUpFilter(setOpen)}
                handleClose={setClose}
                isOpen={isOpen}
                fields={{
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                    sourceMethod: filters.sourceMethod || [],
                }}
            />

            <SimpleTable
                columns={transactionTableColumns}
                RowComponent={TransactionRow}
                isLoading={isFetching}
                rows={preparedData}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                error={error}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
