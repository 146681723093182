import { useParams } from 'react-router-dom';

import { PageContainer } from '@dock/react';

import { BreadcrumbsWrapper } from '../../common';
import { A2ATransactionDetailTable } from './components/A2ATransactionDetailTable';

export function A2ATransactionDetails() {
    const { id: a2aId = '', transactionId = '' } = useParams();
    return (
        <PageContainer testId="A2ATransactionDetailsPage">
            <BreadcrumbsWrapper />
            <A2ATransactionDetailTable a2aId={a2aId} transactionId={transactionId} />
        </PageContainer>
    );
}
