import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { prefillDatesFromAlways } from '@dock/common';
import { DateRangeDropDown, FilterButton, SearchBox, SimpleTable } from '@dock/react';
import { useModal } from '@dock/react-hooks';

import {
    filterWrapperStyles,
    useCardTransactionsFilters,
    CardTransactionsFilters,
    CardTransactionsSearchFields,
    transformCardTransactionsParams,
    TableFiltersWrapper,
} from '../../../../common';
import cardDetailsLang from '../../../../lang/cardDetails';
import { useCardTransactions } from '../../../../services';
import { cardTransactionsColumns } from './cardTransactionsColumns';
import { CardTransactionsRow } from './CardTransactionsRow';

export function CardTransactionsTable() {
    const { id: cardId = '' } = useParams();

    const { isOpen, setClose, setOpen } = useModal();

    const {
        filters,
        handleDateFilter,
        handlePopUpFilter,
        handleSearch,
        handleSortOrder,
        isPopFilterActive,
        sorting,
    } = useCardTransactionsFilters(CardTransactionsSearchFields.transactionId);

    const { data, error, fetchNextPage, isFetching, isFetchingNextPage } = useCardTransactions({
        params: transformCardTransactionsParams({ ...filters, cardId }),
    });

    const [dateFrom, dateTo] = prefillDatesFromAlways(filters.from, filters.to);

    return (
        <>
            <TableFiltersWrapper>
                <SearchBox
                    label={cardDetailsLang.SEARCH_BY_TRANSACTION_ID}
                    setLastSearchValue={handleSearch}
                    initValue={filters.text || ''}
                />

                <Box sx={{ ...filterWrapperStyles }}>
                    <DateRangeDropDown
                        handleFilters={handleDateFilter}
                        from={dateFrom}
                        to={dateTo}
                    />

                    <FilterButton isFilterOn={Boolean(isPopFilterActive)} onClick={setOpen} />
                </Box>
            </TableFiltersWrapper>
            <CardTransactionsFilters
                isOpen={isOpen}
                handleClose={setClose}
                onApply={handlePopUpFilter(setOpen)}
                fields={{
                    kind: filters.kind || [],
                    maxAmount: filters.maxAmount || '',
                    minAmount: filters.minAmount || '',
                    sourceMethod: filters.sourceMethod || [],
                    status: filters.status || [],
                }}
            />

            <SimpleTable
                columns={cardTransactionsColumns}
                RowComponent={CardTransactionsRow}
                isLoading={isFetching}
                rows={data}
                error={error}
                isLoadingNextPage={isFetchingNextPage}
                onReachingBottom={fetchNextPage}
                sorting={sorting}
                onSortClick={handleSortOrder}
            />
        </>
    );
}
